// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-analyzing-index-mdx": () => import("./../src/pages/analyzing/index.mdx" /* webpackChunkName: "component---src-pages-analyzing-index-mdx" */),
  "component---src-pages-ethics-index-mdx": () => import("./../src/pages/ethics/index.mdx" /* webpackChunkName: "component---src-pages-ethics-index-mdx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learning-index-mdx": () => import("./../src/pages/learning/index.mdx" /* webpackChunkName: "component---src-pages-learning-index-mdx" */),
  "component---src-pages-learning-rds-modules-mdx": () => import("./../src/pages/learning/rds-modules.mdx" /* webpackChunkName: "component---src-pages-learning-rds-modules-mdx" */),
  "component---src-pages-managing-index-mdx": () => import("./../src/pages/managing/index.mdx" /* webpackChunkName: "component---src-pages-managing-index-mdx" */),
  "component---src-pages-page-2-tsx": () => import("./../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-programming-index-mdx": () => import("./../src/pages/programming/index.mdx" /* webpackChunkName: "component---src-pages-programming-index-mdx" */),
  "component---src-pages-publishing-index-mdx": () => import("./../src/pages/publishing/index.mdx" /* webpackChunkName: "component---src-pages-publishing-index-mdx" */),
  "component---src-pages-sources-index-mdx": () => import("./../src/pages/sources/index.mdx" /* webpackChunkName: "component---src-pages-sources-index-mdx" */),
  "component---src-pages-visualizing-index-mdx": () => import("./../src/pages/visualizing/index.mdx" /* webpackChunkName: "component---src-pages-visualizing-index-mdx" */)
}

